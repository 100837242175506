<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app width="270px">
        <v-list height="225" class="mt-n2">
          <a-row type="flex" justify="center" align="middle">
            <a-col :span="24" class="centerdiv">
              <!-- <v-avatar size="120">
                <img src="../assets/logo_color.png" />
              </v-avatar> -->
              <v-img src="../assets/PetnerLogo.png" width="100%"></v-img>
            </a-col>
            <!-- <a-col :span="24" class="centerdiv">
              <p style="font-weight: 400; font-size: 18px">{{ username }}</p>
            </a-col> -->
          </a-row>
        </v-list>
        <v-divider></v-divider>
        <a-menu mode="vertical">
          <a-menu-item
            v-for="item in Navigator"
            :key="item.key"
            @click="ChangePage(item)"
          >
            <a-icon :type="item.icon" />
            <span class="pl-5">{{ item.text }}</span>
          </a-menu-item>
        </a-menu>
      </v-navigation-drawer>
      <v-app-bar app color="#b8deff">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          style="color: white"
        ></v-app-bar-nav-icon>
        <h3>{{ Header }}</h3>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="Logout"
          >ออกจากระบบ<a-icon type="import" class="ml-2"
        /></v-btn>
      </v-app-bar>
      <v-main class="ma-4">
        <router-view></router-view>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      // Powerball US
      // Powerball Aus
      // MegaMillions US
      // EuroJackpot EU
      // Mini Loto JP
      drawer: true,
      Header: "",
      // picture: "",
      username: "",
      // NavigatorAdmin: [
      //   {
      //     key: "managelotto",
      //     text: "จัดการลอตเตอรี่",
      //     path: "/managelotto",
      //     name: "managelotto",
      //     icon: "user",
      //   },
      //   {
      //     key: "menu1",
      //     text: "จัดการผู้ใช้งาน",
      //     path: "/ManageUser",
      //     name: "ManageUser",
      //     icon: "user",
      //   },
      //   {
      //     key: "menu99",
      //     text: "จัดการแอดมิน",
      //     path: "/ManageAdmin",
      //     name: "ManageAdmin",
      //     icon: "user",
      //   },
      //   {
      //     key: "menu98",
      //     text: "จัดการแบนเนอร์",
      //     path: "/Managebanners",
      //     name: "ManageBanner",
      //     icon: "user",
      //   },
      //   {
      //     key: "ManageOrder",
      //     text: "รายการสั่งซื้อ ลอตเตอรี่ไทย",
      //     path: "/ManageOrder",
      //     name: "ManageOrder",
      //     icon: "shopping",
      //   },
      //   {
      //     key: "ManageOrderWinnings",
      //     text: "จัดการล็อตเตอรี่ที่ถูกรางวัล",
      //     path: "/ManageOrderWinnings",
      //     name: "ManageOrderWinnings",
      //     icon: "shopping",
      //   },
      //   {
      //     key: "ManageDealer",
      //     text: "จัดการตัวแทน",
      //     path: "/ManageDealer",
      //     name: "ManageDealer",
      //     icon: "user",
      //   },
      //   {
      //     key: "configService",
      //     text: "จัดการระบบ",
      //     path: "/configService",
      //     name: "configService",
      //     icon: "unordered-list",
      //   },
      // ],
      // NavigatorHelper: [
      //   {
      //     key: "ManageOrder",
      //     text: "รายการสั่งซื้อ ลอตเตอรี่ไทย",
      //     path: "/ManageOrder",
      //     name: "ManageOrder",
      //     icon: "shopping",
      //   },
      // ],
      NavigatorDefault: [
        {
          key: "menu1",
          text: "My Profile",
          path: "/ManageUserEdit",
          name: "ManageUserEdit",
          icon: "user",
        },
        {
          key: "ManageSetWork",
          text: "ตั้งค่าการรับงาน",
          path: "/ManageSetWork",
          name: "ManageSetWork",
          icon: "shopping",
        },
        {
          key: "ManageWorkInformation",
          text: "งานของฉัน",
          path: "/ManageWorkInformation",
          name: "ManageWorkInformation",
          icon: "shopping",
        },
        {
          key: "ManagePriceList",
          text: "ตารางราคา",
          path: "/ManagePriceList",
          name: "ManagePriceList",
          icon: "shopping",
        },
        // {
        //   key: "ManageBanner",
        //   text: "จัดการ Banner",
        //   path: "/ManageBanner",
        //   name: "ManageBanner",
        //   icon: "shopping",
        // },
      ],
      NavigatorAdmin: [
        // {
        //   key: "menu1",
        //   text: "My Profile",
        //   path: "/ManageUserEdit",
        //   name: "ManageUserEdit",
        //   icon: "user",
        // },
        // {
        //   key: "ManageSetWork",
        //   text: "ตั้งค่าการรับงาน",
        //   path: "/ManageSetWork",
        //   name: "ManageSetWork",
        //   icon: "shopping",
        // },
        {
          key: "UserList",
          text: "ร้านทั้งหมด",
          path: "/UserList",
          name: "UserList",
          icon: "user",
        },
        {
          key: "ManageWorkInformation",
          text: "งานทั้งหมด",
          path: "/ManageWorkInformation",
          name: "ManageWorkInformation",
          icon: "shopping",
        },
        {
          key: "ManagePriceList",
          text: "ตารางราคา",
          path: "/ManagePriceList",
          name: "ManagePriceList",
          icon: "shopping",
        },
        {
          key: "ManageBanner",
          text: "จัดการ Banner",
          path: "/ManageBanner",
          name: "ManageBanner",
          icon: "shopping",
        },
      ],
      // NavigatorAccounting: [
      //   {
      //     key: "menu1",
      //     text: "จัดการผู้ใช้งาน",
      //     path: "/ManageUser",
      //     name: "ManageUser",
      //     icon: "user",
      //   },
      //   {
      //     key: "ManageOrder",
      //     text: "รายการสั่งซื้อ ลอตเตอรี่ไทย",
      //     path: "/ManageOrder",
      //     name: "ManageOrder",
      //     icon: "shopping",
      //   },
      //   {
      //     key: "ManageOrderWinnings",
      //     text: "จัดการล็อตเตอรี่ที่ถูกรางวัล",
      //     path: "/ManageOrderWinnings",
      //     name: "ManageOrderWinnings",
      //     icon: "shopping",
      //   },
      //   {
      //     key: "ManageDealer",
      //     text: "จัดการตัวแทน",
      //     path: "/ManageDealer",
      //     name: "ManageDealer",
      //     icon: "user",
      //   },
      //   {
      //     key: "configService",
      //     text: "จัดการระบบ",
      //     path: "/configService",
      //     name: "configService",
      //     icon: "unordered-list",
      //   },
      // ],
      Navigator: [],
    };
  },
  created() {
    var checkuser = JSON.parse(
      Decode.decode(localStorage.getItem("DDLotAdmin"))
    );
    console.log(checkuser)
    if(checkuser.user.user_type == 'ADMIN'){
      this.Navigator = this.NavigatorAdmin;
    }else{
      this.Navigator = this.NavigatorDefault;
    }
    
    // console.log("checkuser", checkuser.user);
    // if (
    //   checkuser.user.telNumber == "0974244224" ||
    //   checkuser.user.telNumber == "0809454464" ||
    //   checkuser.user.telNumber == "0990569595" ||
    //   checkuser.user.telNumber == "0969696671"
    // ) {
    //   this.Navigator = this.NavigatorAdmin;
    // } else if (checkuser.user.agentId == null) {
    //   this.Navigator = this.NavigatorAccounting;
    // } else {
    //   this.Navigator = this.NavigatorDefault;
    // }

    // เชค helper //
    // if (checkuser.user.isHelper == 1) {
    //   if (checkuser.user.userType == "ADMIN") {
    //     if (
    //       checkuser.user.telNumber == "0974244224" ||
    //       checkuser.user.telNumber == "0809454464" ||
    //       checkuser.user.telNumber == "0990569595" ||
    //       checkuser.user.telNumber == "0969696671"
    //     ) {
    //       this.Navigator = this.NavigatorAdmin;
    //     } else {
    //       this.Navigator = this.NavigatorDefault;
    //     }
    //   } else {
    //     this.Navigator = this.NavigatorHelper;
    //     this.$router.currentRoute.path == "/ManageOrder"
    //       ? ""
    //       : this.$router.push("/ManageOrder");
    //   }
    // }
    // ออยล์ 098-154-6656
    // น๊อต 082-689-5453
    // โอ๋ 086-505-7173
    // เอิร์ธ 082-553-8685
    // Paanrada 080-945-4464
    // Paanrada 098-582-8555
    // Paanrada 094-636-9942
    // บอย 091-824-9553
    // จิ๊บ 0649855654
    // ไอซ์ 0927728421
    // แบงค์ 091-794-7959
    // อุ้ม 063-649-7464
    // ปุย 090-971-2035
    // ติ๋ม 090-965-6599
    // นา 081-866-8354
    // สุพิศ 088-901-7930
    // นวล 081-931-9256

    this.CheckHeader();
  },
  methods: {
    async ChangePage(val) {
      // // console.log('เข้า ChangePage', val)
      // // console.log(val, this.$router.currentRoute)
      if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    CheckHeader(val) {
      this.Header = val;
    },
    Logout() {
      localStorage.removeItem("DDLotAdmin");
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.centerdiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.div_overflow {
  overflow: auto;
  width: 100%;
  height: 89vh;
}
</style>
<style>
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
}
</style>
