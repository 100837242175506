<template>
  <!-- <v-app style="background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%)"> -->
  <v-app style="background-color: #b8deff">
  <!-- #b8deff -->
    <router-view />
  </v-app>
</template>

<script>
export default {
  methods: {},
};
</script>
<style>
@import "./assets/styles.css";
</style>
